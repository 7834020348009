.questions-container {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.question-content {
  padding: 10px;
  margin-bottom: 15px;
  &.header {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  .timer {
    text-align: center;
  }
}
.question-header {
  display: flex;
  align-items: center;
  height: 100px;
}
.question-actions {
  display: flex;
  align-items: center;
}
.question-text {
  span {
    font-size: 1rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  img {
    float: unset !important;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}
.spacer {
  flex: 1;
}
.answers {
  max-width: 800px;
  margin: 0 auto;
  display: block;
}
.answer-label {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.float-right-button {
  float: right;
}
.question-list {
  // margin-top: 100px;
}
#simple-popper {
  padding: 10px;
  width: 440px;
  max-height: calc(100% - 50px);
}
.ref-button {
  margin-bottom: 10px !important;
}
.ref-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ref-search {
  width: 100%;
  margin-top: 10px;
  font-size: 1.2rem;
  padding: 5px;
  border-color: #0277bd;
}
.ref-body {
  // height: 100%;
  width: 420px;
  // overflow: scroll;
}
.ref-title {
  margin-top: 10px !important;
  text-align: center;
}
.grid-line {
  border-bottom: 1px solid #e8e8e8;
}

.chip {
  margin-left: 5px;
  &.wrong {
    color: #fff;
    background-color: #b71c1c;
    div {
      background-color: #b71c1c;
      border-color: #7f0000;
      color: #7f0000;
      border: 2px solid;
    }
  }
  &.correct {
    color: #fff;
    background-color: #2e7d32;
    div {
      background-color: #2e7d32;
      border-color: #005005;
      color: #005005;
      border: 2px solid;
    }
  }
}
.current-item {
  background-color: #bbdefb !important;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.end-examButton {
  margin-right: 10px !important;
}
