body {
  margin: 0;
  background-color: #eceff1;
}

.content {
  padding: 50px 100px;
  max-width: 1200px;
  margin: 0 auto;
}
.tour-text {
  margin-top: 10px !important;
}
.tour-container {
  display: flex;
  align-items: center;
}
.tour-img {
  margin-right: 10px;
  width: 50%;
}
// /*Container, container body, iframe*/
// .mce-tinymce, .mce-container-body, #code_ifr {
//   min-height: 100% !important;
// }
// /*Container body*/
// .mce-container-body {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }
// /*Editing area*/
// .mce-container-body .mce-edit-area {
//   position: absolute;
//   top: 69px;
//   bottom: 37px;
//   left: 0;
//   right: 0;
// }
// /*Footer*/
// .mce-tinymce .mce-statusbar {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }
.tox-tinymce {
  border: none !important;
}
