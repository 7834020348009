.contact-form {
  max-width: 800px;
  margin: 0 auto;
}
.contact-submit {
  // float: right;
  margin-top: 10px !important;
}
.contact-img {
  max-width: 100%;
}
