.auth {
  height: calc(100vh - 64px);
  max-width: calc(100vw - 64px);
  .error-message {
    color: #c23d4b;
  }
}
.auth-left {
  position: relative;
  padding: 0 30px;

  @media screen and (min-width: 0px) and (max-width: 600px) {
    display: none;
  }
}

.auth-right {
  position: relative;
}
.text-wrapper {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.particles {
  height: 100%;
  width: 100%;
}
.auth-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
// .auth-content {
//   text-align: center;
//   padding: 50px 100px;
//   // max-width: 800px;
// }
.discount-text {
  text-align: center;
  color: #333;
  background: #ffc107;
  border-radius: 10px;
  padding: 5px 15px;
  margin-bottom: 10px;
}

// PAYMENT FORM
.payment-form {
  .payment-button {
    margin: 10px auto;
    display: block;
  }
}

fieldset {
  border: 1px solid #666;
  padding: 15px;
  border-radius: 6px;
  max-width: 600px;
  margin: 50px auto;
}

fieldset legend {
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #666;
  background-color: #fafafa;
}

fieldset legend + * {
  clear: both;
}
.input-label {
  margin-bottom: 10px;
}
.pay-button {
  margin-top: 15px !important;
}
.card-wrapper {
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.payment-error {
  color: #c23d4b;
}
.loading-container {
  text-align: center;
}

.wechat-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.purchase-items {
  max-width: 600px !important;
  margin: 0 auto !important;
}
.check-icon {
  color: forestgreen;
  font-size: 80px !important;
  margin: 10px auto;
  display: block !important;
}
.secure-payment {
  display: flex;
  align-items: center;
  justify-content: space-around;
  img {
    height: 30px;
  }
}

.processing-paypal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paypal-button {
  margin: 0 auto;
}
