.home-page-section {
  max-width: 1200px;
  margin: 0 auto;
}
.hero {
  background-color: #0277bd;
  margin-bottom: 100px;
  h3,
  h6 {
    color: #fff;
  }
}
.hero-border {
  position: absolute;
  bottom: 0;
  transform: translateY(98%);
  left: 0px;
}
.use-cases {
  margin-bottom: 100px;
  .exam-notes {
    width: 400px;
  }
}

.use-cases-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  padding: 0 16px;
  .title {
    color: #1e4963;
    font-family: "Merriweather", serif;
    font-weight: 900;
    text-align: center;
    margin-bottom: 25px;
    position: relative;
    font-size: 3rem;
    &::after {
      content: " ";
      border-bottom: 10px solid #ffc107;
      width: 25%;
      position: absolute;
      bottom: -24px;
      border-radius: 10px;
      left: 0;
      transform: translateX(150%);
    }
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  .text {
    width: 50%;
    div {
      .check-mark {
        margin-right: 20px;
      }
      display: flex;
    }
  }
  .image-wrapper {
    width: 400px;
    height: auto;
    img {
      width: 70%;
      margin: 0 auto;
      display: block;
    }
  }
}

.referral-img {
  max-width: 300px;
  display: block;
  margin: 0 auto;
}
.referral-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  div {
    text-align: center;
  }
}

.section-title {
  font-family: "Merriweather", serif !important;
  font-weight: 700 !important;
  font-size: 3rem !important;
  color: #1e4963;
}
