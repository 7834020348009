.dashbaord-container {
  padding: 10px;
  margin-bottom: 24px;
}
.dashboard {
  padding-top: 50px;
}
// PROFILE
.profile {
  text-align: center;
  padding: 30px 20px;
  height: 428px;
  .avatar {
    margin: 10px auto;
    height: 70px;
    width: 70px;
  }
}
// END PROFILE

// QUESTIONS
.question-list-secondary {
  display: inline !important;
}
.beta-message {
  display: flex;
  width: 70%;
  margin: 0 auto;
  background: #e8e8e8;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  span {
    margin-left: 10px;
  }
}
.question-list-recall {
  background-color: #e8e8e8;
  display: inline-block;
  border-radius: 5px;
  padding: 2px 5px;
}
// END QUESTIONS

// PROGRESS
.progress {
}
.progress-wrapper {
  display: flex;
  flex-direction: column;
  height: 369px;
  justify-content: center;
  align-items: center;
}
// END PROGRESS

// Exams
.recall-checkbox {
}
.exam-percent {
  font-size: 14px;
}

.revision-grid {
  text-align: center;
}
.revision {
  min-height: 400px;
  .revisionButton {
    width: 300px;
    margin: 0 auto;
    display: block;
    text-align: center;
  }
}
.bookmarks {
  overflow-y: scroll;
  height: 400px;
  &.flex {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  overflow-y: scroll;
  min-height: 300px;
  .revise-bookmarks-button {
    width: 100%;
  }
}
.referal-section {
  padding: 25px;
  // color: #fff !important;
  // background-color: #0277bd !important;
  display: flex;
  .referral-img {
    width: 20%;
  }
  .link {
    display: inline-block;
    margin: 5px 0 !important;
    border-bottom: solid 2px #ffc107;
    padding: 5px;
    font-weight: 500;
  }
  .section-title {
    font-size: 40px !important;
    color: #0277bd//;
  }
  .social-links {
    text-align: center;
  }
  .button-link {
    display: inline-block;
  }
  .dont-show-button {
    margin: 0 auto;
    display: block;
    opacity: 0.5;
  }
}
