// Price
.price-border {
  transform: rotateX(180deg);
  margin-bottom: -10px;
}
.price-border-reverse {
  margin-top: -10px;
  transform: scale(-1, 1);
}
.price-section {
  background: #0277bd;
  padding: 10px 10px 50px;
  position: relative;
}
.price-container {
  border-radius: 50px;
  h2 {
    color: #fff;
  }
  .pricing-subtitle {
    background: #ffc107;
    border-radius: 10px;
    max-width: 710px;
    margin: 20px auto;
  }
}
.price-card {
  border-radius: 15px;
  .discount-text {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.price-value {
  display: flex;
}
.select-plan-button {
  margin-top: 10px !important;
}
.feature-text {
  margin: 0 auto !important;
  background: white;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid;
  max-width: 950px;
  display: block !important;
}
.sample-button {
  max-width: 230px;
  margin: 15px auto !important;
  display: block !important;
  text-align: center;
}
// EndPrice
